@use "src/assets/scss/utils" as u;

.main {
  width: 100%;
}

.mobile {
  width: 100%;
  display: flex;
  margin: 0 0 u.size('unit-3x');
}

.mobile-btn {
  flex: 1;

  &:not(:last-child) {
    margin: 0 u.size('unit') 0 0;
  }

}

.mobile-modal {

  &-header {
    align-items: center;
    height: u.size('unit-7x');
    min-height: u.size('unit-7x');
    width: 100%;
    color: u.var-color('text');
    position: relative;
    border-bottom: 1px solid u.var-color('grey-15');
  }

  &-content {
    padding: 0;
  }
}

.mobile-category {
  &-link {
    min-width: 0;
    width: 100%;
    display: flex;
    min-height: u.size('unit-6x');
    align-items: center;
  }

  width: 100%;
  background-color: transparent;
  user-select: none;
  border-color: u.var-color('grey-15');
  border-width: 0 0 1px 0;
  color: u.var-color('text');
  padding: 0 (u.size('unit-3x') + u.size('unit-half')) 0 u.size('unit');
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-style: solid;


  &:last-child {
    margin: 0 0 u.size('unit-2x');
  }

  &-check {
    position: absolute;
    top: 50%;
    display: flex;
    right: u.size('unit');
    transform: translate(0, -50%);
    @include u.square(u.size('unit-2x'));
    margin: 0 0 0 u.size('unit');
    color: u.var-color('primary');

    svg {
      @include u.square(u.size('unit-2x'));
    }
  }

  &-icon {
    //@include u.square(u.size('unit-6x'));

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 u.size('unit') 0 0;

    svg {
      @include u.square(u.size('unit-half-3x'));
    }
  }
}

.mobile-category-list {
  padding: 0 0 0 u.size('unit-half-2x');
  width: 100%;

  .mobile-category {
    padding: 0 (u.size('unit-3x') + u.size('unit-half')) 0 0;
  }
}

.mobile-categories {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: u.size('text-sm');

  &:global('category') {
    .mobile-category-icon {
      color: u.var-color('secondary');
    }
  }
}

