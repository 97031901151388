@use "src/assets/scss/utils" as u;

.main {
  display: flex;
  background-color: u.var-color('grey-5');
  border: 1px solid u.var-color('grey-25');
  border-radius: 8px;
  height: 24px;
  line-height: 24px;
  align-items: center;

  margin: 0 0 u.size('unit-2x');
}

.btn {
  border: none;
  position: relative;

  svg {
    @include u.square(u.size('unit'));
  }

  padding: 0 u.size('unit-2x');
  transition: u.change('button-non-bg');
  @include u.mixins-hover {
    color: u.var-color('primary');
  }

  &[disabled="true"] {
    color: u.var-color('grey-25');
  }

  &-minus {
    margin: 0 8px 0 0;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: u.var-color('grey-25');
      @include u.mixins-absolute-center-vertical;
      right: 0;
    }
  }

  &-plus {
    margin: 0 0 0 8px;

    &:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: u.var-color('grey-25');
      @include u.mixins-absolute-center-vertical;
      left: 0;
    }
  }
}

.input {
  padding: 0 u.size('unit-half');
  height: 100%;
  width: u.size('unit-6x');
  text-align: center;
  -moz-appearance: textfield;
  display: block;
  border: none;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  background: none;
  outline: 0;
}
