@use "src/assets/scss/utils" as u;

.wrapper {
  position: relative;
  width: 100%;
  max-width: u.size('page-lg');

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include u.below('md') {
    justify-content: flex-end;
  }
}

.content {
  margin: auto 0;
  border-radius: u.size('unit');
  background-color: #fff;
  transition: all .25s ease-in-out;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 96%;

  @include u.below('md') {
    max-height: 85%;
    margin: auto 0 0;
  }
}

.header {
  width: 100%;

  @include u.below('md') {
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid u.var-color('grey-15');
    height: u.size('unit-7x');
    min-height: u.size('unit-7x');
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: u.size('unit') u.size('unit') 0 0;
    background-color: #fff;
  }

  &-title {
    @include u.below('md') {
      font-weight: 500;
      padding: u.size('unit');
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      text-align: center;
    }
  }
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  flex: 1;
  min-height: 0;

  @include u.below('md') {
    height: 100%;
    overflow: auto;
  }

  &-wrapper {
    width: 100%;
    padding: u.size('unit') u.size('unit-2x');

    @include u.below('md') {
      padding: 0 u.size('unit');
      height: 100%;
    }
  }

  [data-simplebar="init"] {
    max-height: 100%;
    display: flex;
  }

  :global {
    .simplebar-content-wrapper {
      @include u.below('md') {
        overflow: visible !important;
      }
    }

    .simplebar-mask {
      @include u.below('md') {
        overflow: visible !important;
      }
    }

    .simplebar-track {
      @include u.below('md') {
        display: none;
      }
    }
  }

  &:global(.disabled-scroll) {
    :global(.simplebar-content-wrapper) {
      @include u.below('md') {
        overflow-y: hidden !important;
      }
    }
  }
}

.footer {
  width: 100%;
}

.overlay {
  z-index: u.z-index('modal');
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(#000, .7);

  * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  &:global(.modal-transition-enter) {
    .content {
      @include u.below('md') {
        transform: translate(0, 100vh);
      }
      @include u.above('md') {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:global(.modal-transition-enter-active) {
    .content {
      @include u.below('md') {
        transform: translate(0, 0);
      }
      @include u.above('md') {
        opacity: 1;
        visibility: visible;
      }
    }

  }

  &:global(.modal-transition-enter-done) {
    .content {
      @include u.below('md') {
        transform: translate(0, 0);
      }
      @include u.above('md') {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:global(.modal-transition-exit) {
    @include u.above('md') {
      display: none;
    }
    @include u.below('md') {
      background-color: transparent;
    }

    .content {
      @include u.below('md') {
        transform: translate(0, 0);
      }
    }
  }

  &:global(.modal-transition-exit-active) {
    @include u.below('md') {
      .content {
        transform: translateY(100vh);
      }
    }
  }
}

.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  &-instance {
    background-color: transparent;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 100%;
  border: none;
  background: none;
  outline: none;
  margin: 0 0 0 u.size('unit-half-2x');
  color: #fff;
  transition: color .25s ease-in-out;
  width: u.size('unit-3x');
  display: flex;
  padding: 0;

  @include u.mixins-hover() {
    color: u.color('primary-lt');
  }

  @include u.below('md') {
    bottom: 100%;
    top: auto;
    left: auto;
    right: u.size('unit-half');
    margin: 0 0 u.size('unit');
  }

  @include u.below('xs') {
    width: u.size('unit-half-3x');
  }

  svg {
    @include u.square(u.size('unit-3x'));

    @include u.below('xs') {
      @include u.square(u.size('unit-half-3x'));
    }
  }
}

.content {
  &:global(.loading) {
    @include u.above('md') {
      opacity: 0 !important;
      visibility: hidden !important;
    }
    @include u.below('md') {
      transform: translate(0, 100vh) !important;
    }
  }
}

