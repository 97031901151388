@use "src/assets/scss/utils" as u;

.forget {
  color: u.var-color('text-ltr');
}

.form {
  background-color: #fff;
  font-size: u.size('text-sm');
  width: 100%;
  padding: 0 0 u.size('unit-2x');
  @include u.below('md') {
    padding: 0
  }
}

.extenrals {
  margin: 0 0 u.size('unit-2x');
  border: 1px solid u.var-color('grey-1');
  width: 100%;

  @include u.below('md') {
    margin: 0 0 u.size('unit-3x');
  }

  &-list {
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 100%;
  }

  &-item {
    padding: u.size('unit');
  }
}

.register {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  &-link {
    color: u.var-color('secondary');

    @include u.mixins-hover {
      color: u.var-color('secondary-dk');
    }
  }
}

.login {
  position: absolute;
  top: -#{u.size('unit-half')};
  bottom: -#{u.size('unit-half')};
  left: -#{u.size('unit-half')};
  right: -#{u.size('unit-half')};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &-icon {
    margin: 0 0 u.size('unit-2x');
    color: u.var-color('success');

    svg {
      @include u.square(u.size('unit-6x') * 2)
    }


  }

  &-text {
    text-align: center;
    width: 100%;
    padding: 0 u.size('unit');
    font-size: u.size('text-md');
    font-weight: 500;
  }
}

.error {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: u.var-color('danger');
  width: 100%;
  margin: 0 0 u.size('unit-2x');
  padding: 0 u.size('unit');
  height: u.size('unit-6x');

  &-icon {
    margin: 0 u.size('unit-2x') 0 0;
  }

  &-text {

  }
}
