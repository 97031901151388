@use "src/assets/scss/utils" as u;

.modal {
  @include u.above('md') {
    height: 100%;
  }
}

.main {
  border-radius: u.size('unit');
  @include u.below('md') {
    border-radius: u.size('unit') u.size('unit') 0 0;
  }
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 0 u.size('unit-10x');

  @include u.below('xs') {
    padding: u.size('unit') 0 u.size('unit-5x');
  }

  @include u.below('sm') {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.purchase-breadcrumbs {
  position: relative;
  width: 100%;
  min-width: 100%;
  margin: u.size('unit') 0 u.size('unit-4x');
  padding: u.size('unit');
  border-bottom: 1px solid u.var-color('grey-15');
  @include u.mixins-box-shadow-md(6);
  border-radius: u.size('unit');

  @include u.below('md') {
    order: 10;
    min-height: auto;
    margin: u.size('unit-2x') 0;
  }
}

.image {
  flex: 1;
  margin: 0 u.size('unit-4x') 0 0;

  @include u.below('sm') {
    width: 100%;
    margin: 0 0 u.size('unit-3x');
    position: relative;
  }
}

.info {
  flex: 1;
  min-width: 0;
  @include u.below('md') {
    width: 100%;
  }
}

.title {
  font-size: u.size('text-lg');
  font-weight: 500;
  transition: color .25s ease-in-out;
  color: u.color('text');

  @include u.mixins-hover() {
    color: u.var-color('secondary');
  }

  @include u.below('md') {
    font-size: u.size('text-md');
  }
}

.price {
  color: u.var-color('primary');
  font-weight: 700;
  font-size: u.size('text-xl');

  @include u.below('md') {
    font-size: u.size('text-lg');
  }
}

.description {
  font-size: u.size('text-md');
  width: 100%;
  min-width: 100%;
  margin: u.size('unit-4x') 0 0;

  &-label {
    margin: 0 0 u.size('unit');
    font-weight: 500;
  }
}

.external {
  z-index: 2;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: u.size('unit');
  overflow: hidden;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: u.size('unit-8x');
  background-color: #fff;
  @include u.mixins-box-shadow-md(6);

  &-link {
    display: flex;
    padding: 0 u.size('unit-2x');
    border-radius: u.size('unit');
    font-size: u.size('text-md');

    border: 1px solid u.var-color('secondary');

    @include u.mixins-text-height(u.size('unit-4x'));
    color: u.var-color('secondary');

    @include u.mixins-hover(true) {
      color: u.var-color('secondary-dk');
    }
  }
}

.panel {
  padding: u.size('unit') 0;
  display: flex;
  align-items: center;
  margin: u.size('unit-2x') 0 0;

  @include u.below('md') {
    margin: u.size('unit') 0 0;
  }
}

.meta {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;

  &-reviews {
    display: flex;

    &-rating {
      margin: 0 u.size('unit') 0 0;

      @include u.below('xs') {
        margin: 0 u.size('unit-half') 0 0;
      }
    }

    &-link {
      line-height: u.size('unit-half-3x');
      transition: color .25s ease-in-out;
      color: u.color('text');
      font-size: u.size('text-md');

      @include u.mixins-hover() {
        color: u.var-color('secondary');
      }

      @include u.below('xs') {
        font-size: u.size('text-sm');
        line-height: u.size('unit-2x');
      }
    }
  }
}
