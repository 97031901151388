@use "src/assets/scss/utils" as u;
@use 'sass:color';

.item {
  width: 100%;
  border-radius: u.size('unit');
  background-color: u.var-color('white');
  @include u.mixins-box-shadow-md(2);
  position: relative;
  min-width: 0;

  @include u.mixins-hover {

    .details-btn {
      opacity: 1;
      visibility: visible;
      transition: opacity 0s ease .5s, visibility 0s ease .5s;
    }

    .favorite {
      opacity: 1;
      transition: opacity 0s ease .5s, visibility 0s ease .5s;
      visibility: visible;
    }

    .link-wrap {
      opacity: 1;
      transition: opacity 0s ease .5s, visibility 0s ease .5s;
      visibility: visible;
    }
  }

  @include u.above('md') {
    &:global(.hover), &:global(.add-cart) {
      transition: box-shadow 0s;
      transition-delay: .5s;
      box-shadow: none;
      z-index: 3;

      .preview {
        display: block;
      }
    }
    &:global(.add-cart) {
      z-index: 4;
    }
  }

  &:global(.simple) {
    box-shadow: none;


    .image {
      &:before {
        @include u.below('sm') {
          padding-bottom: 100%;
        }
      }

    }

  }

  &-tag {
    pointer-events: none;
    position: absolute;
    left: u.size('unit');
    top: u.size('unit');
    font-size: u.size('text-sm');
    color: #fff;
    border-radius: u.size('unit-half');
    padding: 0 u.size('unit-half');
    @include u.mixins-text-height(u.size('unit-3x'));

    @include u.below('xs') {
      @include u.mixins-text-height(u.size('unit-half-3x'));
      text-align: center;
      font-size: u.size('text-xs');
      top: auto;
      left: 0;
      bottom: 0;
      border-radius: 0;
      width: 100%;
    }


    &:global(.new) {
      background-color: color.scale(u.get-color-value('blue'), $lightness: 15%);
      @include u.below('md') {
        background-color: rgba(u.get-color-value('blue'), .75);
      }
    }

    &:global(.hit) {
      background-color: color.scale(u.get-color-value('orange'), $lightness: 15%);
      @include u.below('md') {
        background-color: rgba(u.get-color-value('orange'), .75);
      }
    }

    &:global(.sale) {
      background-color: color.scale(u.get-color-value('green'), $lightness: 15%);
      @include u.below('md') {
        background-color: rgba(u.get-color-value('green'), .75);
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.image {
  width: 100%;
  position: relative;
  display: flex;
  border-radius: u.size('unit') u.size('unit') 0 0;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;

    @include u.below('sm') {
      padding-bottom: 150%;
    }
  }

  &-link {
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: u.size('unit') u.size('unit') u.size('unit-2x') u.size('unit');

  @include u.below('sm') {
    padding: u.size('unit') u.size('unit-half');
  }

  @include u.below('md') {
    flex: 1;
  }
}

.price {
  position: relative;
  font-weight: 700;
  @include u.mixins-text-height(u.size('unit-3x'));
  margin: 0 0 u.size('unit');
  display: flex;
  justify-content: space-between;
  width: 100%;

  &-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    color: u.color('primary');
    @include u.mixins-hover {
      color: u.color('primary-dk');
    }

    @include u.below('md') {
      flex: 1;
    }
  }

  @include u.below('sm') {
    margin: 0 0 u.size('unit-half');
  }
}

@keyframes modal-add-cart-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobile-add-cart {
  @include u.square(u.size('unit-3x'));
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:global(.success) {
    color: u.color('success');
  }

  padding: 0;

  svg {
    @include u.square(u.size('unit-half-3x'))
  }
}

.card-actions {
  display: flex;
  margin: 0 0 0 u.size('unit');

  @include u.above('md') {
    display: none;
  }
}

.title {
  font-size: u.size('text-sm');
  color: u.color('text-dkr');
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  height: 36px;

  @include u.below('sm') {
    height: 30px;
  }

  > span {
    width: 100%;
  }

  &-link {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-skeleton {
    height: u.size('unit-2x');
    width: 100%;
  }

  @include u.below('sm') {
    font-size: u.size('text-xs');
    margin: u.size('unit') 0 0;
  }
}

.action-btn {

  border-radius: 100%;
  background-color: #fff;

  @include u.square(36px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;

  svg {
    @include u.square(20px)
  }

  &-tooltip {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.favorite {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: color .25s ease-in-out;
  right: u.size('unit');

  &-tooltip {
    width: 100%;
    height: 100%;
  }

  top: u.size('unit');


  @include u.mixins-hover(true) {
    color: u.var-color('primary');
  }

  &:global(.active) {
    opacity: 1;
    visibility: visible;
    color: u.var-color('primary');


  }

  @include u.below('md') {
    background-color: transparent;
    @include u.square(20px);
    opacity: 1;
    visibility: visible;
    top: u.size('unit-half');
    right: u.size('unit-half');

  }
}

.link {
  bottom: u.size('unit');
  transition: color .25s ease-in-out;

  @include u.mixins-hover(false) {
    color: u.color('secondary');
  }

  &:global(.active) {
    color: u.color('secondary');
  }

  &-wrap {
    position: absolute;
    bottom: u.size('unit');
    right: u.size('unit');
    opacity: 0;
    visibility: hidden;
  }
}

.images {
  width: u.size('unit-9x');
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  &:before {
    content: '';
    right: -#{u.size('unit-half')};
    width: u.size('unit');
    height: 100%;
    background-color: inherit;
    display: block;
    position: absolute;
    top: 0;
  }

  border-radius: u.size('unit') 0 u.size('unit') u.size('unit');
  padding: u.size('unit') u.size('unit-half') u.size('unit-2x') u.size('unit-half');

  &-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  &-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    padding: (u.size('unit-4x') + u.size('unit-half')) 0;

    &:global(.without-navigation) {
      padding: 0;

      .images-slider-btn {
        display: none;
      }
    }

    &-btn {
      z-index: 1;
      position: absolute;
      left: 0;
      width: 100%;
      height: u.size('unit-3x');
      display: flex;
      justify-content: center;
      align-items: center;

      transition: color .25s ease-in-out;

      &:global(.disabled) {
        color: u.var-color('grey-15');
      }

      svg {
        @include u.square(u.size('unit-2x'));
      }

      &-prev {
        top: 0;
      }

      &-next {
        bottom: 0;
      }
    }

    :global .swiper-container {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      //.swiper-slide {
      //		height: auto;
      //}
    }
  }

  &-item {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: u.size('unit-half');
    overflow: hidden;
    transition: color .25s ease-in-out;
    cursor: pointer;
    border: 1px solid transparent;

    &:not(:global(.active)) {
      @include u.mixins-hover(true) {
        border-color: u.var-color('primary-ltr');
      }
    }

    &:global(.active) {
      border-color: u.var-color('primary');
    }


    &-img {
      @include u.mixins-absolute-center;

    }
  }
}

.props {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  background-color: #fff;
  padding: 0 u.size('unit') 0 u.size('unit-9x');
  color: #000;

  @include u.mixins-box-shadow-md(6);
  border-radius: 0 u.size('unit') u.size('unit');

  &:before {
    content: '';
    top: -#{u.size('unit-3x')};
    height: u.size('unit-4x');
    width: 100%;
    background-color: inherit;
    display: block;
    position: absolute;
    left: 0;
  }

  z-index: 1;


  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: u.size('unit') u.size('unit') u.size('unit-2x');
  }

  &-additional {
    margin: u.size('unit') 0 0;
    width: 100%;
  }

  &-sizes {
    width: 100%;
    position: relative;
    flex: 1;

    @include u.below('md') {
      overflow: hidden;
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      width: u.size('unit-2x');
      height: 100%;
      background: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 100%);
      top: 0;
      right: 0;

      @include u.below('md') {
        display: block;
      }
    }
  }

  &-list {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: u.size('text-xs');
    color: u.var-color('grey-50');
  }

  &-item {
    &:not(:last-child) {
      margin: 0 0 u.size('unit');
    }

    &:first-child {
      margin-top: u.size('unit-half-2x');

      @include u.below('md') {
        margin-top: u.size('unit');
      }
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 u.size('unit-half');
    }
  }

  &-sublist {
    list-style: none;
    display: flex;
    align-items: center;

    @include u.above('md') {
      flex-wrap: wrap;
      margin: -#{u.size('unit-half')} 0 0;
    }

    @include u.below('md') {
      height: u.size('unit-half-3x');
    }

    &-item {
      white-space: nowrap;
      padding: u.size('unit-half');
      background-color: rgba(u.get-color-value('secondary'), 0.2);
      border-radius: u.size('unit-half');
      margin: u.size('unit-half') u.size('unit-half') 0 0;
      font-size: u.size('text-xs');
      max-width: 100%;

      @include u.below('md') {
        font-size: u.size('unit');
        margin: 0 u.size('unit-half') 0 0;
      }

      &-value {
        background-color: rgba(u.get-color-value('secondary'), .09);
        color: u.color('text-lt');
      }

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.preview {
  position: absolute;
  background-color: #fff;
  border-radius: u.size('unit') u.size('unit') 0 0;
  top: -#{u.size('unit')};
  right: -#{u.size('unit')};
  left: -#{u.size('unit-9x')};
  bottom: 0;
  @include u.mixins-box-shadow-md(6);
  z-index: -1;
  display: none;
}

.details-btn {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: rgba(#fff, .8);
  color: initial;
  white-space: nowrap;
  font-weight: 300;
  @include u.mixins-box-shadow-md(2);

  @include u.mixins-hover(true) {
    background-color: #fff;
  }
}

.add-cart {

  &-tooltip {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
  }

  &-preloader {
    z-index: u.z-index('before-modal');
  }
}

.series {
  width: 100%;
  margin: u.size('unit-half-2x') 0 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: u.size('unit-3x');
    height: 100%;
    background: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 100%);
    top: 0;
    right: 0;
  }

  &-title {
    margin: 0 0 u.size('unit-half');
    color: u.var-color('grey-50');
    font-size: u.size('text-xs');
  }

  &-row {
    overflow: hidden;
    display: flex;
    width: 100%;
    font-size: u.size('text-xs');

    &:not(:last-child) {
      margin: 0 0 u.size('unit');
    }

    &-item {
      border-color: u.color('grey-25');
      border-style: solid;
      cursor: pointer;
      border-width: 1px 1px 1px 0;
      padding: u.size('unit-half');
      background-color: u.color('success');
      color: #fff;

      &:first-child {
        border-radius: u.size('unit') 0 0 u.size('unit');
        border-width: 1px;
      }

      &:last-child {
        border-radius: u.size('unit');
      }

      & + &:last-child {
        border-radius: 0 u.size('unit') u.size('unit') 0;
      }

      &:global(.ordered) {
        background-color: u.color('grey-10');
        color: u.color('text');
      }
    }

  }
}

:global {
  .tippy-tooltip.add-cart-theme {
    background-color: u.color('success');
    color: #fff;
    box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);

    [data-animatefill] {
      background-color: transparent;
    }

    .tippy-backdrop {
      background-color: u.color('success')
    }
  }

  .tippy-popper[x-placement^=top] {
    .tippy-tooltip.add-cart-theme {
      [x-arrow] {
        border-top-color: u.color('success');
      }
    }
  }

  .tippy-popper[x-placement^=bottom] {
    .tippy-tooltip.add-cart-theme {
      [x-arrow] {
        border-bottom-color: u.color('success');
      }
    }
  }
}


body:global(.mobile-off) {
  @media (max-device-width: (u.size('md') - 1)) {
    .favorite, .link-wrap, .details-btn {
      opacity: 1;
      visibility: visible;
    }

    .favorite, .link-wrap {
      z-index: 1;
    }

    .details-btn {
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      border: none;
      width: 100%;
      height: 100%;
      &-text {
        display: none;
      }
    }
  }
}
