@use "src/assets/scss/utils" as u;

.list {
  width: 100%;
  display: grid;
  grid-template: auto / repeat(3,1fr);
  grid-gap: u.size('unit-2x') u.size('unit-2x');

  @include u.below('md') {
    grid-template: auto / repeat(4,1fr);
  }

  @include u.below('sm') {
    grid-template: auto / repeat(3,1fr);
    grid-gap: u.size('unit') u.size('unit');
  }
  @include u.below('xs') {
    grid-template: auto / repeat(2,1fr);
    grid-gap: u.size('unit') u.size('unit');
  }

  &:global(#searchProductsList) {
    width: 100%;
    display: grid;
  }
}

.main {
  position: relative;

  &:global(.loading) {
    &:before {
      display: block;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#fff, .5);
    z-index: 1;
    display: none;
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 25vh;
  padding: u.size('unit-2x') 0;

  @include u.below('sm') {
    min-height: 50vh;
    padding: u.size('unit') 0;
  }
}

.empty-label {
  font-weight: 500;
  text-align: center;
  margin: 0 0 u.size('unit-3x');
}

.load-more {
  position: relative;
  margin: u.size('unit-3x') 0 0;
  width: 100%;

  .load-more-preloader {
    height: 100px;

    @include u.below('xs') {
      height: 200px;
    }
  }
}
