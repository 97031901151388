@use "src/assets/scss/utils/index" as u;

.main {
  width: 100%;
  background-color: u.color('grey-5');
  border-radius: u.size('unit');
  padding: u.size('unit');
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: u.size('unit-4x');
}

.rating {
  margin: 0 u.size('unit-2x') 0 -#{u.size('unit-half')};

  &-avg {
    font-size: u.size('text-lg');
    font-weight: 900;
  }
}

.list {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin: u.size('unit-half-3x') 0 0;
}

.item {
  display: table-row;
  font-size: u.size('text-sm');
  line-height: 1;

  > div {
    display: table-cell;
    vertical-align: middle;
  }

  &-label {
    white-space: nowrap;
    color: u.color('text-ltr');
    height: u.size('unit-3x');
  }

  &-count {
    text-align: center;
  }

  &-line {
    width: 100%;
    padding: 0 u.size('unit-half-2x');
  }

  &-bar {
    border-radius: u.size('unit-half');
    height: u.size('unit-half');
    overflow: hidden;
    background-color: u.color('grey-15');


    &-progress {
      background-color: u.color('primary');
      height: 100%;
    }
  }
}
