@use "src/assets/scss/utils" as u;

.main {
  width: 100%;
  height: u.size('unit-5x');
  font-size: u.size('text-sm');
  overflow: hidden;
  display: grid;

  &:global(.lot) {
    grid-template: 'owner purchase catalog status percent' auto / auto minmax(0, auto) minmax(0, auto) auto auto;


  }

  &:global(.catalog) {
    grid-template: 'owner purchase catalog status percent count' auto / auto minmax(0, auto) minmax(0, auto) auto auto auto;
  }

  &:global(.added-lot) {
    grid-template: 'owner purchase status percent' auto / auto 1fr auto auto;
  }

  @include u.below('xs') {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.hr {
  position: relative;
  padding: 0 u.size('unit-2x') 0 0;
  margin: 0 u.size('unit-2x') 0 0;

  @include u.below('xs') {
    padding: 0 0 u.size('unit-2x');
    margin: 0 0 u.size('unit-2x');
  }

  &:after {
    content: '';
    height: 80%;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-color: u.var-color('grey-15');
    width: 1px;
    position: absolute;

    @include u.below('xs') {
      height: 1px;
      width: 100%;
      transform: none;
      top: auto;
      bottom: 0;
    }
  }
}

.owner {
  height: 100%;
  display: flex;
  position: relative;
  grid-area: owner;

  &-avatar {
    margin: 0 u.size('unit') 0 0;
  }
}

.purchase {
  grid-area: purchase;
}

.catalog {
  grid-area: catalog;
}

.status {
  grid-area: status;
}

.percent {
  grid-area: percent;
}

.count {
  grid-area: count;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  &:global(.center) {
    align-items: center;
  }

  &-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: u.var-color('secondary');
    @include u.mixins-hover(true) {
      color: u.var-color('secondary-dk');
    }
  }

  &-label {
    font-size: u.size('text-xs');
    color: u.var-color('text-lt');
  }

  &-text {
    white-space: nowrap;
  }
}
