@use "src/assets/scss/utils" as u;

.modal {
  @include u.above('md') {
    width: auto;
  }

}

.main {
  display: flex;

  &:global(.info) {
    padding: 0 0 0 300px;

    @include u.below('md') {
      padding: 0;
    }
  }
}

.content {
  width: 400px;
  padding: u.size('unit-2x');

  @include u.below('md') {
    width: 100%;
    padding: u.size('unit');
  }
}

.title {
  font-size: u.size('text-xl');
  font-weight: 700;
  margin: 0 0 u.size('unit-3x');
  line-height: u.size('text-height-heading');
  padding: 0 0 u.size('unit');
  border-bottom: 1px solid u.var-color('grey-15');
}

.info {

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    overflow: hidden;

    @include u.below('md') {
      display: none;
    }
  }

  width: 100%;
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, .6);
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include u.mixins-absolute-center;
    width: 100%;
  }

  &-icon {
    color: #fff;
    margin: 0 0 u.size('unit-2x');

    svg {
      @include u.square(u.size('unit-6x'));
    }
  }

  &-text {
    text-align: center;
    width: 100%;
    padding: 0 u.size('unit');
    color: #fff;
    font-size: u.size('text-sm');
    font-weight: 500;
  }
}
