@use "src/assets/scss/utils" as u;

.main {
  display: flex;
  position: relative;
  margin: 0 0 0 -#{u.size('unit-half')};

  &:global(.readonly) {
    .item {
      cursor: auto;
    }
  }

  &:global(.md) {
    .fill {
      height: u.size('unit-4x');
      background-size: u.size('unit5x') u.size('unit-4x');
    }

    .item {
      svg {
        @include u.square(u.size('unit-4x'));
      }
    }
  }

  &:global(.sm) {
    .fill {
      height: u.size('unit-3x');
      background-size: u.size('unit-4x') u.size('unit-3x');
    }

    .item {
      svg {
        @include u.square(u.size('unit-3x'));
      }
    }
  }

  &:global(.xs) {
    .fill {
      height: u.size('unit-2x');
      background-size: u.size('unit-3x') u.size('unit-2x');
    }

    .item {
      svg {
        @include u.square(u.size('unit-2x'));
      }
    }
  }
}

.fill {
  background-position: 0 50%;
  background-repeat: repeat-x;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjI4MzQgMi4wNjU0M0wxMC4zNzc2IDkuOTg0NjdMMS42Mzg5IDExLjI1ODdDMC4wNzE4MDQgMTEuNDg2IC0wLjU1NjIzMiAxMy40MTc5IDAuNTgwMjE0IDE0LjUyNDVMNi45MDI0NSAyMC42ODUyTDUuNDA3MTIgMjkuMzg4QzUuMTM3OTYgMzAuOTYxMSA2Ljc5NDc4IDMyLjEzOTQgOC4xODI0NCAzMS40MDM3TDE2IDI3LjI5NDVMMjMuODE3NiAzMS40MDM3QzI1LjIwNTIgMzIuMTMzNCAyNi44NjIgMzAuOTYxMSAyNi41OTI5IDI5LjM4OEwyNS4wOTc2IDIwLjY4NTJMMzEuNDE5OCAxNC41MjQ1QzMyLjU1NjIgMTMuNDE3OSAzMS45MjgyIDExLjQ4NiAzMC4zNjExIDExLjI1ODdMMjEuNjIyNCA5Ljk4NDY3TDE3LjcxNjYgMi4wNjU0M0MxNy4wMTY4IDAuNjUzODQzIDE0Ljk4OTIgMC42MzU4OTkgMTQuMjgzNCAyLjA2NTQzWiIgZmlsbD0iI0VBNkI0MSIvPgo8L3N2Zz4K")
}

.item {
  cursor: pointer;
  padding: 0 u.size('unit-half');
  color: u.color('grey-20');
  display: flex;
  transition: color .25s ease-in-out;

  &:global(.active) {
    color: u.color('primary');
  }
}
