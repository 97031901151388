@use "src/assets/scss/utils" as u;

@keyframes show {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.main {
  width: 100%;
  animation: show .5s ease-in-out;

  &[data-slider-type='purchase'] {
    :global(.swiper-slide) {
      height: auto;
    }
  }
}

.label {
  margin: 0 0 u.size('unit-2x');
  line-height: u.size('line-height-heading');
  font-size: u.size('text-xl');
  font-weight: 700;

  @include u.below('md') {
    margin: 0 0 u.size('unit');
  }

  @include u.below('xs') {
    font-size: u.size('text-md');
  }
}

.content {
  width: 100%;
  position: relative;

  @include u.mixins-hover {
    .btn {
      opacity: 1;
    }
  }
}

.btn {
  opacity: 0;
  z-index: 1;
  border-radius: 100%;
  background-color: rgba(#fff, .5);
  @include u.square(u.size('unit-6x'));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: u.var-color('text-lt');
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .25s ease-in-out, color .25s ease-in-out, opacity .5s ease-in-out;

  @include u.mixins-hover {
    background-color: #fff;
    color: u.var-color('primary');
  }

  svg {
    @include u.square(u.size('unit-3x'));
  }
}

.prev {
  left: u.size('unit');
}

.next {
  right: u.size('unit');
}

.preloader {
  width: 100%;
  height: 300px;
  position: relative;
}
