@use "src/assets/scss/utils" as u;

@keyframes animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.main {
  width: 100%;
  height: 100%;
  display: inline-block;

  &:global(.space) {
    position: absolute;
    left: 0;
    top: 0;

    .item {
      display: none;

      &:first-child {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.item {
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: u.size('unit-half');
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: animation 1.2s ease-in-out infinite;
}
