@use "src/assets/scss/utils" as u;

.main {
  border-top: 1px solid u.var-color('grey-15');
  padding: u.size('unit-3x') 0 0;
  width: 100%;
}

.prop {
  &:not(:last-child) {
    margin: 0 0 u.size('unit-2x');

    @include u.below('xs') {
      margin: 0 0 u.size('unit');
    }
  }

  width: 100%;
  font-size: u.size('text-sm');

  &-label {
    font-weight: 500;
    margin: 0 0 u.size('unit');
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include u.below('xs') {
      font-size: u.size('text-xs');
    }
  }
}

.props {
  width: 100%;
  display: flex;
  margin: 0 0 -8px;
  flex-wrap: wrap;

  &:global(.error) {
    .props-item-label {
      box-shadow: inset 0 0 0 2px u.var-color('danger');
    }
  }

  &-item {
    position: relative;
    white-space: nowrap;
    margin: 0 8px 8px 0;

    input[type="radio"]:checked {
      ~ .props-item-label {
        box-shadow: inset 0 0 0 2px u.var-color('primary-dk');
      }
    }

    &-label {
      min-width: u.size('unit-4x');
      @include u.mixins-text-height(u.size('unit-3x'));
      border-radius: u.size('unit');
      background: u.var-color('grey-5');
      cursor: pointer;
      box-shadow: inset 0 0 0 1px u.var-color('grey-25');
      display: block;
      padding: 0 u.size('unit');
      text-align: center;
      transition: u.change('label-color');
      @include u.mixins-hover {
        box-shadow: inset 0 0 0 2px u.var-color('primary');
      }

      @include u.below('xs') {
        font-size: u.size('text-xs');
      }
    }
  }
}

.form {
  &:global(.loading) {
    .submit-icon {

      &-text {
        transition: transform 0s ease-in-out .5s, margin .5s ease-in-out;
        transform: translate(100%, 0);
        margin: 0;
      }

      &-background {
        transition: transform .5s ease-in-out .5s;
        transform: translate(0, 0);
      }
    }
  }

  //&:global(.load) {
  //		.lot-submit-icon-text {
  //				transition: margin .5s ease-in-out .5s;
  //				margin: 0 0 0 u.size('unit-2x');
  //				transform: translate(0, 0);
  //		}
  //}

  &:global(.success) {
    .submit-icon-text {
      transition: margin .5s ease-in-out .5s;
      margin: 0 0 0 u.size('unit-2x');
      transform: translate(0, 0);
    }

    .submit-icon-check {
      opacity: 1;
    }

    .submit-icon-background {
      transform: translate(0, 0);
    }
  }

  &:global(.error) {
    .submit-icon-text {
      transition: margin .5s ease-in-out .5s;
      margin: 0 0 0 u.size('unit-2x');
      transform: translate(0, 0);
    }

    .submit-icon-times {
      opacity: 1;
    }

    .submit-icon-background {
      transform: translate(0, 0);
    }
  }
}

.total {
  &-value {
    font-weight: 700;
    font-size: u.size('text-md');
  }
}

.actions {
  width: 100%;
  display: flex;
  margin: u.size('unit-2x') 0 0;

  @include u.below('xs') {
    transform: translateZ(0);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background-color: #fff;
    padding: u.size('unit');
    height: u.size('unit-6x');
    @include u.mixins-box-shadow-md(3);
    border-radius: u.size('unit') u.size('unit') 0 0;
  }
}

.submit {
  @include u.below('xs') {
    @include u.mixins-text-height(u.size('unit-4x'));
    font-size: u.size('text-md');
  }

  &-icon {
    display: flex;
    align-items: center;

    svg {
      @include u.square(u.size('unit-4x'));

      @include u.below('xs') {
        @include u.square(u.size('unit-3x'));
      }
    }

    &:after {
      overflow: hidden;
    }

    &-text {
      margin: 0 0 0 u.size('unit-2x');
      overflow: hidden;
      white-space: nowrap;
    }

    &-background {
      transition: transform .5s ease-in-out .5s;
      transform: translate(0, 100%);
      fill: #fff;
    }

    &-times {
      opacity: 0;
      fill: u.var-color('danger');
      transition: opacity 1s ease-in-out;
    }

    &-check {
      opacity: 0;
      fill: u.var-color('success');
      transition: opacity 1s ease-in-out;
    }

    &-bag {
      fill: #fff;
    }
  }
}

.favorite {
  width: u.size('unit-6x') * 2;
  height: u.size('unit-6x');
  display: flex;
  justify-content: center;
  align-items: center;

  @include u.below('xs') {
    width: u.size('unit-6x');
    height: u.size('unit-4x');
  }

  &-btn {
    cursor: pointer;
    transition: color .25s ease-in-out;
    outline: none;

    @include u.mixins-hover(true) {
      color: u.var-color('primary');
    }

    &:global(.active) {
      color: u.var-color('primary');
    }

    svg {
      @include u.square(u.size('unit-4x'));

      @include u.below('xs') {
        @include u.square(u.size('unit-3x'));
      }
    }
  }
}

.series {
  width: 100%;
  overflow: hidden;

  &-rows {
    width: 100%;
    overflow-y: auto;
    padding: 0 0 u.size('unit-half-2x');
  }

  &-row {
    display: flex;
    width: auto;
    font-size: u.size('text-sm');

    &:not(:last-child) {
      margin: 0 0 u.size('unit');
    }

    &-item {
      border-color: u.color('grey-25');
      border-style: solid;
      cursor: pointer;
      border-width: 1px 1px 1px 0;
      padding: u.size('unit-half');
      background-color: u.color('success');
      color: #fff;

      &:first-child {
        border-radius: u.size('unit') 0 0 u.size('unit');
        border-width: 1px;
      }

      &:last-child {
        border-radius: u.size('unit');
      }

      & + &:last-child {
        border-radius: 0 u.size('unit') u.size('unit') 0;
      }

      &:global(.ordered) {
        background-color: u.color('grey-10');
        color: u.color('text');
      }
    }
  }
}

.add-lot-to-user {
  .prop-label {
    font-size: u.size('text-md');
    font-weight: 400;
  }
}

@keyframes autocompleteStatusLoad {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.autocomplete {
  width: 100%;

  &-status {
    width: 100%;
    position: relative;

    [data-name='autocomplete-input'] {
      padding: 0 u.size('unit-4x') 0 u.size('unit');
    }

    &-tooltip {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      position: absolute;
      right: u.size('unit');
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;

      svg {
        @include u.square(u.size('unit-2x'));
      }

      &-error {
        color: u.color('danger');
      }

      &-success {
        color: u.color('success');
      }

      &-load {
        svg {
          animation: autocompleteStatusLoad 5.4s infinite;
        }
      }
    }
  }
}
