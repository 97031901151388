@use "src/assets/scss/utils" as u;

.main {
  width: 100%;
  position: relative;

  &:global(.indent) {
    padding: 0 0 0 u.size('unit-10x');

    @include u.below('sm') {
      padding: 0 0 0 u.size('unit-8x');
    }
  }
}

.preview {
  overflow: hidden;
  padding: (u.size('unit-4x') + u.size('unit-half')) 0;
  width: u.size('unit-8x');
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  @include u.below('sm') {
    width: u.size('unit-6x');
  }

  &:global(.without-navigation) {
    padding: 0;

    .slider-btn {
      display: none;
    }
  }

  :global .swiper-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.image {
  position: relative;
  overflow: hidden;
  border-radius: u.size('unit');
  border: 1px solid transparent;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.slider-btn {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: u.size('unit-3x');
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  transition: color .25s ease-in-out;

  &:global(.disabled) {
    color: u.var-color('grey-15');
  }

  svg {
    @include u.square(u.size('unit-2x'));
  }

  &-prev {
    top: 0;
  }

  &-next {
    bottom: 0;
  }
}

.item {
  width: 100%;
  position: relative;
  display: flex;
  border-radius: u.size('unit-half');
  overflow: hidden;
  transition: color .25s ease-in-out;
  cursor: pointer;
  border: 1px solid transparent;

  &:not(:global(.active)) {
    @include u.mixins-hover(true) {
      border-color: u.var-color('primary-ltr');
    }
  }

  &:global(.active) {
    border-color: u.var-color('primary');
  }

  &-img {
    @include u.mixins-absolute-center;
  }
}
