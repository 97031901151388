@use "src/assets/scss/utils" as u;

.main {
  width: 100%;
  margin: 0 0 u.size('unit-3x');
  border-bottom: 1px solid u.var-color('grey-15');

  @include u.below('md') {
    margin: 0 0 u.size('unit-2x');
    border: none;
  }
}

.filters {
  width: 100%;

  &-clean {
    line-height: 1.2;
    color: u.var-color('primary');
    transition: color .25s ease-in-out;
    cursor: pointer;
    @include u.mixins-hover {
      color: u.var-color('primary-dk');
    }
    margin: 0 u.size('unit-2x') 0 0;

    @include u.below('md') {
      display: none;
    }
  }

  &-open {
    position: relative;

    &:global(.count) {
      &:before {
        display: flex;
      }
    }

    &:before {
      content: attr(data-mobile-filter-count);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-75%, -50%);
      border-radius: u.size('unit-2x');
      height: u.size('unit-2x');
      min-width: u.size('unit-3x');
      color: #fff;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: u.var-color('primary-dk');
      font-size: u.size('text-xs');
    }
  }

  &-skeleton {
    width: 100%;
    margin: 0 0 u.size('unit-2x');
    height: u.size('unit-3x');
  }

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 0 u.size('unit');
  }
}

.filter {
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  background-color: u.var-color('primary');
  display: flex;
  align-items: center;
  @include u.mixins-text-height(u.size('unit-3x'));
  border-radius: u.size('unit-2x');
  margin: 0 u.size('unit') u.size('unit') 0;
  padding: 0 u.size('unit-half') 0 u.size('unit');

  &-label {
    user-select: none;
    flex: 1;
    margin: 0 u.size('unit') 0 0;
    font-size: u.size('text-sm');
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-btn {
    border-radius: 100%;
    display: flex;
    align-items: center;
    padding: u.size('unit-half');
    background-color: #fff;
    color: u.var-color('primary');
    transition: background-color .25s ease-in-out;

    svg {
      @include u.square(u.size('unit'));
    }

    @include u.mixins-hover {
      background-color: u.var-color('grey-15');
    }
  }
}


.sort {
  width: 250px;
  margin: 0 0 0 auto;

  @include u.below('md') {
    width: auto;
    margin: 0;
  }

  &-select {
    width: 100%;

    @include u.below('md') {
      :global(.c-input) {
        border: none;
      }
    }

    @include u.below('sm') {
      font-size: u.size('text-sm');
      svg {
        @include u.square(u.size('unit'));
      }


    }

  }


}

.top {
  margin: 0 0 u.size('unit-2x');
  width: 100%;
  display: flex;
  align-items: center;

  @include u.below('md') {
    margin: 0;
    justify-content: space-between;
    position: relative;
  }

}

.categories {
  width: 100%;


  &-list {
    width: 100%;
    overflow-x: auto;
    display: flex;
    padding: u.size('unit') 0 u.size('unit-2x');
  }

  &-item {
    display: flex;
    border-radius: u.size('unit');
    background-color: u.var-color('grey-10');
    font-size: u.size('text-sm');
    padding: 0 u.size('unit');
    white-space: nowrap;
    @include u.mixins-text-height(u.size('unit-4x'));

    &:not(:last-child) {
      margin: 0 u.size('unit-half-2x') 0 0;
    }

    &:last-child {
      margin: 0 u.size('unit-half') 0 0;
    }
  }
}

.mobile-btn {
  padding: 0 u.size('unit');
  @include u.square(u.size(36px));
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @include u.square(u.size('unit-half-3x'));
  }

  @include u.above('md') {
    display: none;
  }
}
