@use "src/assets/scss/utils" as u;

.error {
  width: 100%;

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 u.size('unit');
  }

  &-text {
    width: 100%;
    text-align: center;
    font-size: u.size('text-lg');
  }

  &-btn {
    margin: u.size('unit-4x') 0 0;
    font-weight: 700;
  }

  &-data {
    padding: u.size('unit-4x') 0 0;
    margin: u.size('unit-4x') 0 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: u.var-color('grey-15');
      width: 100vw;
    }

    &-indent {
      margin: 0 0 u.size('unit-6x');
    }
  }
}
