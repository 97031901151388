@use "src/assets/scss/utils" as u;

.modal {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}

.main {
  width: 100%;
  height: 100%;
  padding: 0;

  &:global(.loading) {
    &:before {
      display: block;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#fff, .5);
    z-index: 1;
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

.content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: u.size('unit-7x') 0 u.size('unit-10x');
}

.external {
  z-index: u.z-index('before-modal');
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: u.size('unit') u.size('unit') 0 0;
  overflow: hidden;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: u.size('unit-8x');
  background-color: #fff;
  @include u.mixins-box-shadow-md(3);
  padding: 0 u.size('unit');

  &-btn {
    font-size: u.size('text-sm');
    flex: 1;

    &:not(:last-child) {
      margin: 0 u.size('unit-2x') 0 0;
    }
  }
}

.back {
  color: #fff;

  svg {
    @include u.square(u.size('unit-3x'));

    @include u.below('xs') {
      @include u.square(u.size('unit-half-3x'));
    }
  }

  display: flex;
  position: absolute;
  bottom: 100%;
  left: u.size('unit-half');
  margin: 0 0 u.size('unit');

  @include u.below('xs') {
    width: u.size('unit-half-3x');
  }
}

.header {
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid u.var-color('grey-15');
  height: u.size('unit-7x');
  min-height: u.size('unit-7x');
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: u.size('unit') u.size('unit') 0 0;
  background-color: #fff;

  &-title {
    font-weight: 500;
    padding: u.size('unit');
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center;
  }
}
