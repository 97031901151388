@use "src/assets/scss/utils/index" as u;

.main {
  width: 100%;

  &:global(.without-user) {
    .meta {
      margin: 0;

      @include u.below('sm') {
        margin: 0;
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;

  @include u.below('sm') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.user {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;

  &-avatar {
    margin: 0 u.size('unit-2x') 0 0;

    @include u.below('sm') {
      margin: 0 u.size('unit') 0 0;
    }

    [data-ui-component='avatar'] {
      @include u.below('sm') {
        @include u.square(u.size('unit-5x'));
      }
    }
  }

  &-login {
    flex: 1;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: u.size('text-md');

    @include u.below('sm') {
      font-size: u.size('text-sm');
    }

    &-link {
      color: u.color('text');

      @include u.mixins-hover() {
        text-decoration: none;
      }
    }
  }
}

.meta {
  margin: 0 0 0 u.size('unit-2x');
  display: flex;

  @include u.below('sm') {
    flex-direction: column;
    order: -1;
    margin: 0 0 u.size('unit-2x');
  }

  &-date {
    font-size: u.size('text-sm');
    color: u.color('text-lt');
    margin: 0 u.size('unit') 0 0;
    line-height: 18px;

    @include u.below('sm') {
      order: 2;
      margin: u.size('unit') 0 0;
    }
  }
}

.text {
  margin: u.size('unit-3x') 0 0;
  width: 100%;
  font-size: u.size('text-md');

  @include u.below('sm') {
    margin: u.size('unit-2x') 0 0;
  }

  @include u.below('sm') {
    font-size: u.size('text-sm');
  }
}
