@use "src/assets/scss/utils" as u;

.wrapper {

  &-media {
    width: 100%;
    display: flex;
    @include u.below('lg') {
      flex-wrap: wrap;
      justify-content: center;
    }
    @include u.below('sm') {
      flex-direction: column;
    }
  }

  margin: u.size('unit-2x') 0 0;
  display: flex;
}

.btn {

  @include u.above('sm') {
    font-size: u.size('text-sm');
  }

  &-prev {
    margin: 0 u.size('unit-2x') 0 0;

    @include u.below('sm') {
      margin: 0 u.size('unit') 0 0;
    }
  }

  &-next {
    margin: 0 0 0 u.size('unit-2x');

    @include u.below('sm') {
      margin: 0 0 0 u.size('unit');
    }
  }

  &-big {
    width: 100%;
    font-size: u.size('text-md');
    font-weight: 700;
    margin: 0 0 u.size('unit-2x');
  }
}

.list {
  display: flex;

  @include u.below('md') {
    width: 100%;
    justify-content: center;
  }
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include u.mixins-text-height(u.size('unit-4x'));

  &:not(:last-child) {
    margin: 0 u.size('unit') 0 0;

    @include u.below('sm') {
      margin: 0 u.size('unit-half') 0 0;
    }
  }

  width: u.size('unit-4x');
  border-radius: u.size('unit');
  transition: background-color .25s ease-in-out, color .25s ease-in-out;


  &:not(:global(.active)) {
    @include u.mixins-hover(true) {
      background-color: u.var-color('primary-lt');
      color: #fff;
    }
  }

  &:global(.active) {
    background-color: u.var-color('primary');
    color: #fff;
  }

  @include u.below('md') {
    svg {
      @include u.square(u.size('unit-half-2x'))
    }
  }
}

.dots {
  text-align: center;
  width: u.size('unit-4x');
  user-select: none;
  @include u.mixins-text-height(u.size('unit-4x'));
  margin: 0 u.size('unit') 0 0;
}

.go-page {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;

  @include u.below('lg') {
    min-width: 100%;
    justify-content: center;
    margin: u.size('unit-4x') 0 0;
  }

  &-label {
    margin: 0 u.size('unit') 0 0;
  }

  &-input {
    margin: 0 u.size('unit') 0 0;
    width: u.size('unit-8x');
  }

  &-btn {
    font-size: u.size('text-sm');
  }
}

.show-all {
  width: 100%;
  margin: u.size('unit-3x') 0 0;

  &-btn {
    width: 100%;
  }
}

.pagination {
  display: flex;
  @include u.below('md') {
    width: 100%;
    justify-content: space-between;
  }
}

.main {
  width: 100%;
  border-top: 1px solid u.var-color('grey-15');
  font-size: u.size('text-sm');

  &:global(.top) {
    margin: 0 0 u.size('unit-3x');

    @include u.above('md') {
      border-top: none;

      .wrapper {
        margin: 0;
      }
    }

    @include u.below('lg') {
      .go-page {
        display: none;
      }
    }
  }

  &:global(.bottom) {
    margin: u.size('unit-3x') 0 0;
  }
}
