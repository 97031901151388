@use "src/assets/scss/utils" as u;

.main {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  @include u.below('md') {
    flex-direction: column;
  }
}


.content {
  flex: 1;
  padding: 0 0 0 u.size('unit-4x');
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;

  @include u.below('md') {
    width: 100%;
    padding: 0;
  }
}

.top {
  display: flex;
  align-items: center;
  margin: 0 0 u.size('unit-3x');

  @include u.below('md') {
    width: 100%;
    text-align: center;
    flex-direction: column;
    margin: u.size('unit') 0 u.size('unit-3x');
  }
}

.title {
  font-size: u.size('text-xxl');
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  @include u.below('lg') {
    font-size: u.size('text-xl');
  }

  @include u.below('md') {
    font-size: u.size('text-md');
    width: 100%;
  }

  @include u.below('sm') {
    font-size: u.size('text-sm');
  }

  &-category {
    font-weight: 700;
    @include u.below('md') {
      font-size: u.size('text-md');

    }
  }
}

.keyword {
  font-weight: 700;
  display: inline;

  @include u.below('md') {
    display: block;
    font-size: u.size('text-md');
    margin: u.size('unit-half') 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.catalogs-modal-header {
  align-items: center;
  height: u.size('unit-7x');
  min-height: u.size('unit-7x');
  width: 100%;
  color: u.var-color('text');
  position: relative;
  border-bottom: 1px solid u.var-color('grey-15');
}

.catalogs-modal {
  height: 100%;

  [data-simplebar] {
    flex: 1;
  }

  :global(.simplebar-content) {
    height: 100%;
  }

  :global(.simplebar-content-wrapper) {
    height: 100% !important;
  }
}

.catalog-modal-content {
  display: flex;
  height: 100%;
  min-height: 200px;
}

.catalogs-mobile {
  width: 100%;
  margin: 0 0 u.size('unit-2x');
}

.catalogs-mobile-button {
  width: 100%;
}
