@use "src/assets/scss/utils" as u;

.main {
  position: relative;
  width: 240px;

  @include u.below('lg') {
    width: 220px;
  }

  @include u.below('md') {
    display: none;
  }
}

.skeleton {
  flex: 1;
  width: 100%;
}

.content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  //@include u.mixins-box-shadow-md(6);
  //background: u.var-color('white');
  border-radius: u.size('unit');
  width: 100%;
  position: relative;
  padding: 0 0 u.size('unit');
}

.filter {
  width: 100%;
  margin: 0 0 u.size('unit-3x');

  &-title {
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 u.size('unit-2x');
  }

  &-item {

    &:not(:last-child) {
      margin: 0 0 u.size('unit');
    }

    &-checkbox {
      span {
        font-size: u.size('text-sm');
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &-link {
      font-size: u.size('text-sm');
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 500;

      @include u.below('md') {
        font-size: u.size('text-md');
        font-weight: 400;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &:global(.active) {
      @include u.above('md') {
        .filter-item-link {
          border-radius: u.size('unit');
          padding: u.size('unit-half') u.size('unit');
          background-color: rgba(u.get-color-value('primary'), .25);
        }
      }
    }
  }

  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    &-links {
      .filter-item {
        @include u.above('md') {
          margin: 0;
          padding: u.size('unit-half') 0;
        }
        @include u.below('md') {
          position: relative;
          &:global(.active) {
            padding: 0 (u.size('unit-3x') + u.size('unit-half')) 0 u.size('unit');
          }
        }

      }
    }

    &:global(.show) {
      border-top: 1px solid u.var-color('grey-15');
      border-bottom: 1px solid u.var-color('grey-15');
      padding: u.size('unit') 0;
      height: 300px;

      .filter-item {
        padding-right: u.size('unit-half-2x');
      }
    }
  }

  &-search {
    margin: 0 0 u.size('unit-half-2x');
  }

  &-more {
    line-height: 1.2;
    margin: u.size('unit') 0 0;
    color: u.var-color('primary');
    transition: color .25s ease-in-out;
    cursor: pointer;
    @include u.mixins-hover {
      color: u.var-color('primary-dk');
    }
  }

  &-empty {
    color: u.var-color('grey-25');
    font-size: u.size('text-md');
  }
}

.filter-categories {
  &-parent {
    width: 100%;
    margin: 0 0 u.size('unit');
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    //&:not(:last-child) {
    //		margin: 0 0 u.size('unit');
    //}
    padding: u.size('unit-half') 0;

    &:global(.active) {
      > span.filter-categories-link {

        border-radius: u.size('unit');
        padding: u.size('unit-half') u.size('unit');
        background-color: rgba(u.get-color-value('primary'), .25);
      }
    }
  }

  &-link {
    font-size: u.size('text-sm');
    font-weight: 500;
    //display: -webkit-box;
    //-webkit-line-clamp: 2;
    //-webkit-box-orient: vertical;
    //overflow: hidden;

  }

  &-back {
    margin: 0 u.size('unit-half') 0 0;
    @include u.square(u.size('unit-2x'));
    min-width: u.size('unit-2x');
  }

  &-sublist {
    width: 100%;
    padding: 0 0 0 u.size('unit-2x');

    //.filter-categories-link {
    //		display: inline;
    //}
  }
}

.filter-tag {

  &-item {
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 u.size('unit-half-2x');
    }

    &-checkbox {
      cursor: pointer;
      font-size: u.size('text-md');
      font-weight: 700;

      display: flex;
      align-items: center;

      svg {
        @include u.square(20px);
        margin: 0 u.size('unit') 0 0;
        transition: color .25s ease-in-out;
      }

      @include u.mixins-hover {
        svg {
          color: u.var-color('primary');
        }
      }

      &:global(.selected) {
        svg {
          color: u.var-color('primary');
        }
      }
    }
  }
}

.filter-price {
  &-item {
    width: 100%;
    @include u.below('md') {
      margin: u.size('unit-3x') auto 0;
      width: 80%;
    }

  }

  @include u.below('md') {
    .filter-wrapper {
      padding: u.size('unit-4x') u.size('unit') 0;
    }
  }

  &-values {
    margin: u.size('unit-2x') 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      width: 40%;
      text-align: center;
    }

    &-hr {
      width: 20px;
      border-top: 1px solid u.var-color('grey-15');
    }

    @include u.below('md') {
      margin: u.size('unit-2x') auto 0;
      width: 80%;
    }
  }
}

.filter-mobile {
  &-icon {
    margin: 0 0 0 auto;
    color: u.var-color('primary');

    svg {
      @include u.square(u.size('unit-2x'));
    }
  }

  &-item {
    @include u.below('md') {
      height: u.size('unit-6x');
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid u.var-color('grey-15');
      padding: 0 u.size('unit');
      margin: 0;

      margin: 0 !important;
    }
  }
}

.filter-wrapper {
  width: 100%;

  @include u.below('md') {
    overflow-y: auto;
    margin: 0 0 u.size('unit-2x');
  }
}

.filter-search {
  @include u.below('md') {
    flex: 1;
    width: auto;
    margin: 0;
  }


  &-cancel {
    padding: 0 u.size('unit');
    margin: 0 -#{u.size('unit-10x')} 0 0;
    width: u.size('unit-10x');
    transition: margin .25s ease-in-out;
    @include u.mixins-text-height(u.size('unit-4x'));
  }

  &-wrapper {
    overflow: hidden;
    @include u.below('md') {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: u.size('unit');


      &:global(.focus) {
        .filter-search-cancel {

          margin: 0;
        }
      }
    }


  }
}

.filter {
  &:global(.mobile) {
    @include u.below('md') {
      margin: 0;

      .filter-title {
        height: u.size('unit-6x');
        display: flex;
        align-items: center;
        font-weight: 400;
        border-bottom: 1px solid u.var-color('grey-15');
        padding: 0 u.size('unit');
        justify-content: space-between;
        font-size: u.size('text-sm');
        margin: 0;

        .filter-title-text {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .filter-title-meta {
          font-size: u.size('text-xs');
          color: u.var-color('text-ltr');
          margin: u.size('unit') 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        .filter-title-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 0 u.size('unit');

          svg {
            @include u.square(u.size('unit-half-3x'));
          }
        }
      }

      &:not(.filter-inline) {
        .filter-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          visibility: hidden;
          background-color: #fff;
          transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
          z-index: u.z-index('body-overlay');
          margin: 0;
          padding: u.size('unit-7x') 0 0;
        }
      }

      &.filter-inline {
        .filter-wrapper {
          margin: 0;
        }
      }


      .filter-categories-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;


        .filter-categories-link {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }

      .filter-tag-item {
        margin: 0;

        .filter-tag-item-checkbox {
          font-weight: 400;
          font-size: u.size('text-sm');
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            @include u.square(u.size('unit-2x'));
          }
        }
      }

      .filter-item-checkbox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: u.size('text-sm');
      }

      &:global(.open-list) {
        .filter-wrapper {
          opacity: 1;
          visibility: visible;
        }

        .filter-list {
          @include u.below('md') {
            padding: 0 0 u.size('unit-9x');
          }
        }
      }
    }
  }
}

.filter-catalog-search {
  height: u.size('unit-6x');
  margin: 0;

  @include u.below('md') {
    height: u.size('unit-8x');
    padding: 0 u.size('unit');
    display: flex;
    align-items: center;
    border-bottom: 1px solid u.var-color('grey-15');
  }
}

.filter-catalog-search-form {
  position: relative;;
  z-index: 2;
  width: 100%;
  display: flex;
  border-radius: u.size('unit');
  border: 2px solid u.var-color('primary');
  background-color: #fff;
}

.filter-catalog-search-field {
  position: relative;
  flex: 1;

}


.filter-catalog-search-input {
  width: 100%;
  border-radius: u.size('unit') 0 0 u.size('unit') !important;
  height: 28px;
  border: none;
  font-size: u.size('text-sm');
  padding: 0 u.size('unit-4x') 0 u.size('unit');
  background-color: transparent !important;

  &:focus {
    border-color: none;
    box-shadow: none;
  }
}

.filter-catalog-search-button {
  border-radius: 0 u.size('unit') u.size('unit') 0;
  margin: -2px -2px -2px 0;

  svg {
    @include u.square(u.size('unit-half-3x'));
  }
}

.filter-catalog-search-reset {
  position: absolute;
  right: u.size('unit');
  top: 50%;
  padding: 0;
  transform: translateY(-50%);
  transition: color .25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  border: none;
  display: flex;

  &:global(.show) {
    opacity: 1;
    visibility: visible;
  }

  svg {
    @include u.square(u.size('unit-2x'));
  }

  @include u.mixins-hover {
    color: u.var-color('primary');
  }
}

.filter-fixed-item {
  width: 100%;
  display: flex;


}

.filter-fixed-label {
  width: 100%;
  border-radius: u.size('unit');
  padding: u.size('unit-half') u.size('unit');
  background-color: rgba(u.get-color-value('primary'), .25);
  font-size: u.size('text-sm');
}
